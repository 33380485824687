import { TeamId, TeamSettings } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { getUniversalHelpers } from "../../helpers";
import { firestoreLiftDocsSubToBifrostSub } from "../../internal-utils/firestoreLiftSubToBifrostSub";

export function teamSettings__client__getMultiTeamSettingsSubscription(p: { teamIds: TeamId[] }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return firestoreLiftDocsSubToBifrostSub(h.TeamSettings.docsSubscription(p.teamIds));
}
