import { TeamId, TeamSettings } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";
import { getUniversalHelpers } from "../../helpers";

export async function teamSettings__server__getTeamSettings(p: { teamId: TeamId }): Promise<TeamSettings | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (!p.teamId) {
    return null;
  }

  let teamSettings = await h.TeamSettings.getDoc(p.teamId);
  return teamSettings;
  // SERVER_ONLY_TOGGLE
}

teamSettings__server__getTeamSettings.auth = async (r: express.Request) => {
  await validateToken(r);
};
