import { Org, OrgSettings, TeamSettings } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import express from "express";

export async function teamSettings__server__updateTeamSettings(p: { id: string; teamSettings: Partial<TeamSettings> }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.TeamSettings.update({ id: p.id, doc: p.teamSettings });

  // SERVER_ONLY_TOGGLE
}

teamSettings__server__updateTeamSettings.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};
// i18n certified - complete
