import { AccountId, Org, OrgRegistrationPackageId, OrgId, OrgSeasonId, OrgSettings, TeamSettings } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken, validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import express from "express";
import _ from "lodash";

export async function teamSettings__client__setAssignedRegistrationPackageForSeason(
  p: {
    teamId: string;
    orgSeasonId: OrgSeasonId;
  } & ({ type: "set"; orgRegistrationPackageId: OrgRegistrationPackageId | false } | { type: "delete" })
) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.TeamSettings.setPath({
    id: p.teamId,
    pathObj: {
      registrationPackageIdBySeasonId: {
        [p.orgSeasonId]: true
      }
    },
    value: _.cloneDeep({
      registrationPackageIdBySeasonId: {
        [p.orgSeasonId]: p.type === "delete" ? h._MagicDeleteValue : p.orgRegistrationPackageId
      }
    })
  });
}
// i18n certified - complete
